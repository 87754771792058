import axios, { AxiosError, AxiosResponse } from 'axios';
const addressUrl = `${process.env.VUE_APP_ROOT_API}/Address`;

export const AddressService = {
  GetAddress(addressId: string) {
    return axios.get(`${addressUrl}/GetAddress?addressId=${addressId}`);
  },
  GetShippingAddressesFull(
    shouldIncludeCustomerPickup: boolean = true,
    getProofAddressesOnly: boolean = false
  ): Promise<AxiosResponse> {
    return axios.get(
      `${addressUrl}/GetShippingAddresses?shouldIncludeCustomerPickup=${shouldIncludeCustomerPickup}&getProofAddressesOnly=${getProofAddressesOnly}`
    );
  },
  GetShippingAddressesCondensed() {
    return axios.get(`${addressUrl}/GetShippingAddressesCondensed/`);
  },
  GetBillingAddresses(isCondensed: boolean = true) {
    return axios.get(`${addressUrl}/GetBillingAddresses?isCondensed=${isCondensed}`);
  },
  GetCountries(): Promise<AxiosResponse> {
    return axios.get(`${addressUrl}/GetCountries`);
  },
  GetStates(): Promise<AxiosResponse> {
    return axios.get(`${addressUrl}/GetStates`);
  },
  UpdateAddressDefault(addressId: string, isBilling: boolean) {
    var model = {
      AddressId: addressId,
      IsBilling: isBilling
    };
    return axios.post(`${addressUrl}/UpdateAddressDefault`, model);
  },
  AddNewBillingAddress(model: any) {
    return axios.post(`${addressUrl}/AddNewBillingAddress`, model);
  },
  VerifyAddress(model: any) {
    return axios.post(`${addressUrl}/VerifyAddress`, model);
  },
  AddNewShippingAddress(model: any) {
    return axios.post(`${addressUrl}/AddNewShippingAddress`, model);
  },
  UpdateAddress(model: any) {
    return axios.post(`${addressUrl}/UpdateAddress`, model);
  },
  UpdateRetentionAddress(model: any) {
    return axios.post(`${addressUrl}/UpdateRetentionAddress`, model);
  },
  RemoveAddress(addressId: string) {
    return axios.get(`${addressUrl}/RemoveAddress/${addressId}`);
  },
  UpdateRollDirection(addressId: string, rollDirectionId: number) {
    return axios.get(
      `${addressUrl}/UpdateRollDirection?addressId=${addressId}&rollDirectionId=${rollDirectionId}`
    );
  }
};
